<template>
    <div class="wrap" :class="{ wrap_en: $i18n.locale == 'EN' }">
        <my-header></my-header>
        <div class="section">
            <div class="banner">
                <p>{{ $t('Public.UBIService.banner.title') }}</p>
            </div>
            <div class="content">
                <div class="big_data">
                    <p class="detail_title" :class="{ detail_title_en: $i18n.locale == 'EN' }">
                        {{ $t('Public.UBIService.lovBigData.title1') }} <br />
                        {{ $t('Public.UBIService.lovBigData.title2') }}
                    </p>
                    <div class="data_service">
                        <ul>
                            <li v-for="(item, index) in $t('Public.UBIService.lovBigData.list')" :key="index">
                                <div class="service_icon">
                                    <img :src="require('../../public/images/service/ubi/service_icon' + (index + 1) + '.png')" />
                                </div>
                                <p>{{ item.title }}</p>
                                <div class="data_service_text">{{ item.text }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hardware" v-show="0">
                    <p class="detail_title">{{ $t('Public.UBIService.intelVehicle.title') }}</p>
                    <div class="vehicle">
                        <ul>
                            <li>
                                <div class="vehicle_icon"><img src="../../public/images/service/ubi/obd.png" /></div>
                                <div class="vehicle_text">
                                    <template v-if="$i18n.locale == 'EN'">
                                        <div>
                                            <b>OBD</b>
                                        </div>
                                        <p>On Board Diagnostics</p>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <b>OBD</b>
                                            <span>(On Board Diagnostics)</span>
                                        </div>
                                        <p>车载诊断系统</p>
                                    </template>
                                </div>
                            </li>
                            <li class="active">
                                <div class="vehicle_icon"><img src="../../public/images/service/ubi/dms.png" /></div>
                                <div class="vehicle_text">
                                    <template v-if="$i18n.locale == 'EN'">
                                        <div>
                                            <b>DMS</b>
                                        </div>
                                        <p>Driver monitor system</p>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <b>DMS</b>
                                            <span>(Driver monitor system)</span>
                                        </div>
                                        <p>驾驶员状态监测系统</p>
                                    </template>
                                </div>
                            </li>
                            <li>
                                <div class="vehicle_icon"><img src="../../public/images/service/ubi/dvr.png" /></div>
                                <div class="vehicle_text">
                                    <template v-if="$i18n.locale == 'EN'">
                                        <div>
                                            <b>DVR</b>
                                        </div>
                                        <p>Digital Video Recorder</p>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <b>DVR</b>
                                            <span>(Digital Video Recorder)</span>
                                        </div>
                                        <p>车载硬盘录像仪</p>
                                    </template>
                                </div>
                            </li>
                            <li class="active">
                                <div class="vehicle_icon"><img src="../../public/images/service/ubi/adas.png" /></div>
                                <div class="vehicle_text">
                                    <template v-if="$i18n.locale == 'EN'">
                                        <div>
                                            <b>ADAS</b>
                                        </div>
                                        <p>Advanced Driving Assistance System</p>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <b>ADAS</b>
                                            <span>(Advanced Driving Assistance System)</span>
                                        </div>
                                        <p>高级驾驶辅助系统</p>
                                    </template>
                                </div>
                            </li>
                            <li>
                                <div class="vehicle_icon"><img src="../../public/images/service/ubi/lanya.png" /></div>
                                <div class="vehicle_text">
                                    <template v-if="$i18n.locale == 'EN'">
                                        <div>
                                            <b>Bluetooth Beacon</b>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <b>Bluetooth Beacon</b>
                                        </div>
                                        <p>蓝牙信标</p>
                                    </template>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue';
import MyFooter from '@/components/MyFooter.vue';

export default {
    name: 'UBIService',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {
            src: ''
        };
    },
    methods: {},
    mounted() {}
};
</script>
<style scoped>
.galileo {
    padding: 0.7rem 0.27rem 0.96rem;
}
.galileo ul {
    width: 5.26rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 0.3rem;
    margin: 0 auto;
}
.galileo li {
    width: 2rem;
    margin-bottom: 0.78rem;
    text-align: center;
}
.galileo_introduce {
    line-height: 1.71;
    margin: 0.7rem 0 0.6rem;
    text-align: justify;
}
.wrap_en .galileo_introduce {
    text-align: left;
    text-align: justify;
}
.galileo_img {
    width: 2rem;
    margin-bottom: 0.2rem;
}
.hardware {
    width: 100%;
    padding: 0.7rem 0 0.34rem;
    background: #f6fbff;
}
.data_service {
    width: 100%;
    position: relative;
    margin: 0.96rem 0 1.1rem;
}
.data_service::before,
.data_service::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 3.74rem;
    z-index: 50;
    width: 1px;
    height: 6.33rem;
    background-color: #f4f3f3;
}
.data_service::after {
    width: 7.12rem;
    height: 1px;
    top: 3.16rem;
    left: 0.19rem;
}
.wrap_en .data_service::before {
    height: 10rem;
}
.wrap_en .data_service::after {
    top: 5.5rem;
}
.vehicle {
    width: 6.5rem;
    margin: 0.84rem auto 0;
    position: relative;
}
.data_service ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: space-between;
    width: 100%;
    height: 6.33rem;
}
.data_service li {
    width: 3.74rem;
    /* height: 2.3rem; */
    font-size: 0.24rem;
    color: #666;
    text-align: center;
    line-height: 1.5;
    padding: 0 0.37rem;
}
.data_service_text {
    /* margin-top: 0.3rem; */
}
.wrap_en .data_service ul {
    height: 10rem;
}
.wrap_en .data_service li {
    height: auto;
}
.vehicle li {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.56rem;
}
.vehicle_text {
    font-size: 0.24rem;
}
.vehicle_text b {
    font-size: 0.36rem;
    font-weight: 500;
    margin-right: 0.15rem;
}
.vehicle_text p {
    color: #666666;
    margin-top: 0.08rem;
}
.vehicle_icon {
    width: 0.8rem;
    margin-right: 0.3rem;
}
.service_icon {
    width: 0.8rem;
    margin: 0 auto;
}
.data_service .flex {
    width: 100%;
    justify-content: flex-start;
}
.data_service li p {
    font-size: 0.34rem;
    font-weight: 500;
    color: #333;
    margin: 0.14rem 0 0.21rem;
    /* width: 2.3rem; */
}
.banner {
    width: 100%;
    height: 4.4rem;
    padding: 1.88rem 0 0 0.77rem;
    background: url(../../public/images/service/ubi/banner.jpg) no-repeat;
    background-size: 100% 100%;
    background-color: #0c1f3d;
}
.banner p {
    font-size: 0.46rem;
    line-height: 1.39;
    color: #fff;
}
.content {
    padding-top: 0.5rem;
}
.detail_title {
    font-size: 0.32rem;
    font-weight: 500;
    text-align: center;
}
.big_data .detail_title {
    font-size: 0.42rem;
}
.wrap_en .detail_title {
    font-size: 0.42rem;
}
.service_type {
    margin-top: 1rem;
}
.service_type li {
    width: 100%;
    display: flex;
    padding: 0.56rem 3.5rem 0.5rem;
    position: relative;
    min-height: 3.8rem;
}
.service_text {
    width: 7.2rem;
    margin-right: 0.2rem;
}
.service_img {
    width: 4.4rem;
}
.service_type li.active .service_text {
    width: 6.1rem;
}
.service_type li.active .service_img {
    margin-right: 0.62rem;
}
.service_type li.active .service_text_top {
    justify-content: flex-end;
}
.service_type li.active .service_text_top div {
    margin: 0 0 0 0.2rem;
}
.service_type li.active .service_text_middle,
.service_type li.active .service_text_bottom {
    padding: 0 0.76rem 0 0;
    text-align: right;
}
.service_text_top {
    display: flex;
    align-items: center;
    font-size: 0.26rem;
    padding-top: 0.43rem;
    padding-left: 0.5rem;
}
.service_text_top div {
    width: 0.6rem;
    margin-right: 0.2rem;
}
.service_text_middle {
    font-size: 0.16rem;
    color: #666;
    padding-left: 1.3rem;
    line-height: 2;
    text-align: justify;
}
.service_text_bottom {
    font-size: 0.18rem;
    margin-top: 0.28rem;
    padding-left: 1.3rem;
}
.service_type li.active .service_text_bottom,
.service_type li:nth-child(3) .service_text_bottom {
    margin-top: 0.5rem;
}
.service_text_bottom:hover {
    color: #05a3e4;
    cursor: pointer;
}
.detail_title_en {
    width: 6rem;
    margin: 0 auto;
}
</style>
